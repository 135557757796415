import React, { StrictMode } from "react"
import ReactDOM from "react-dom"
import { AppProvider } from "providers/AppProvider"
import { AuthProvider } from "providers/AuthProvider"
import App from "./components/App"
import * as Sentry from "@sentry/react"
import UAParser from "ua-parser-js"

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "0.1"),
        environment: process.env.NODE_ENV
    })
}

// Parse user agent and set device information as tags
const parser = new UAParser()
const result = parser.getResult()
Sentry.setTag("device.model", result.device.model || "unknown")
Sentry.setTag("device.vendor", result.device.vendor || "unknown")
Sentry.setTag("device.type", result.device.type || "unknown")
Sentry.setTag("os.name", result.os.name || "unknown")
Sentry.setTag("os.version", result.os.version || "unknown")
Sentry.setTag("browser.name", result.browser.name || "unknown")
Sentry.setTag("browser.version", result.browser.version || "unknown")
Sentry.setTag("app.version", process.env.REACT_APP_VERSION || "unknown")


ReactDOM.render(
    <StrictMode>
        <AppProvider>
            <AuthProvider>
                <App />
            </AuthProvider>
        </AppProvider>
    </StrictMode>,
    document.getElementById("root")
)
