import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    FunctionComponent,
    RefObject,
    Fragment,
    useCallback
} from "react"
import { useHistory } from "react-router-dom"
import LoaderButton from "./widgets/LoaderButton"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import ShiftFound from "./ShiftFound/ShiftFound"
import Shift from "../interfaces/Shift"
import Worker from "../interfaces/Worker"
import { AuthContext } from "../providers/AuthProvider"
import InvalidCredentials from "./InvalidCredentials"
import FaceNotFound from "./FaceNotFound"
import { LocalFaceRecognitionModel } from "../interfaces/LocalFaceRecognitionModel"
import LocalBlazeFaceModel from "../utils/LocalBlazeFaceModel"
import { MotionDetector, MotionResult } from "../interfaces/MotionDetector"
import SimpleMotionDetector, { SimpleMotionParams } from "../utils/SimpleMotionDetector"
import { InteractionMode } from "../interfaces/InteractionMode"
import CountdownLights from "./CountdownLights"
import NotEnabled from "./NotEnabled"
import { useInterval } from "../utils/Tools"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import jsQR from "jsqr"
import routes from "../routes"
import { AppContext } from "../providers/AppProvider"
import * as Sentry from "@sentry/react"

interface DebugMode {
    drawAllCanvases: boolean
    stopPhotoCapture: boolean
    motionFractionPercentageThreshold: number
    motionPixelSampleSize: number
    motionPixelDiffThreshold: number
}

window.debugMode = {
    drawAllCanvases: false,
    stopPhotoCapture: false,
    motionFractionPercentageThreshold: 0.05, //  fraction of viewport that needs to have motion to count as valid (0-100)
    motionPixelSampleSize: 20, //  motion detector resolution 'chunks'. The lower the number, the more accurate (>0)
    motionPixelDiffThreshold: 50 //  the literal pixel change in R colour from RGB to count as a unit of change. (0-254)
} as DebugMode

const idealVideoDimensions: ViewportDimensions = {
    width: 640,
    height: 480
}

//  Fraction of the video viewport is actually used for the photo - the 'center bit', should roughly correlate with the 'overlay'
const viewportVideoFraction = 0.6

const imageParams = {
    type: "image/jpeg",
    quality: 0.6
}

const initialCountdownSteps = 4

enum CaptureMode {
    LOADING_VIDEO,
    PHOTO_READY,
    PHOTO_TAKEN
}

enum MatchStatus {
    NOT_MATCHED_YET,
    SHIFT_NOT_FOUND,
    SHIFT_FOUND,
    INVALID_CREDENTIALS,
    FACE_NOT_FOUND
}

interface DrawingSize {
    xOffset: number
    yOffset: number
    width: number
    height: number
}

interface ViewportDimensions {
    width: number
    height: number
}

interface FindFaceRequest {
    face?: string
    qrCodeHash?: string
    workerId?: number
    includeEarlyFinish: boolean
    contextOrgId: number
    interactionMode: InteractionMode
    siteIds: number[]
}

interface FindFaceResponse {
    success: boolean
    shift: Shift
    worker: Worker
    contextForOrgId: string
}

interface RenderParams {
    context: CanvasRenderingContext2D
    canvas: HTMLCanvasElement
    video: HTMLVideoElement
}

const loadLocalFaceModel = async (setLocalFaceModel: any) => {
    const model: LocalBlazeFaceModel = new LocalBlazeFaceModel()
    model
        .load()
        .then(() => {
            setLocalFaceModel(model)
        })
        .catch(error => {
            Sentry.captureException(error)
            console.error("Error when loading face model", error)
        })
    return () => {
        setLocalFaceModel(null)
    }
}

const drawVideoToCanvas = (renderParams: RenderParams): void => {
    const drawingSize = getFractionalDrawingSize(renderParams.video)
    renderParams.context.drawImage(
        renderParams.video,
        drawingSize.xOffset,
        drawingSize.yOffset,
        drawingSize.width,
        drawingSize.height,
        0,
        0,
        renderParams.canvas.width,
        renderParams.canvas.height
    )
}

const getFractionalDrawingSize = (videoRef: HTMLVideoElement): DrawingSize => {
    const sourceWidth = videoRef.videoWidth
    const sourceHeight = videoRef.videoHeight

    const profileWidth = videoRef.videoWidth * viewportVideoFraction
    const profileHeight = videoRef.videoHeight * viewportVideoFraction

    const sourceXOffset = (sourceWidth - profileWidth) / 2
    const sourceYOffset = (sourceHeight - profileHeight) / 2

    return {
        xOffset: sourceXOffset,
        yOffset: sourceYOffset,
        width: profileWidth,
        height: profileHeight
    }
}

const getRenderParamsIfValid = (
    videoHasStarted: boolean,
    videoRef: RefObject<HTMLVideoElement>,
    canvasRef: RefObject<HTMLCanvasElement>
): RenderParams | null => {
    if (!videoRef || !videoRef.current || !videoHasStarted || !canvasRef || !canvasRef.current) {
        return null
    }

    const context = canvasRef.current.getContext("2d")

    if (!context) {
        return null
    }

    return {
        context,
        video: videoRef.current,
        canvas: canvasRef.current
    }
}

const CapturePhoto: FunctionComponent = () => {
    const {
        operationalModeConfig,
        onSetOperationalModeConfig,
        whiteLabelConfig,
        onSetWhiteLabelConfig,
        hasFeatureSwitch,
        Message
    } = useContext(AuthContext)
    const { isOffline } = useContext(AppContext)
    const [localFaceModel, setLocalFaceModel] = useState<LocalFaceRecognitionModel | null>(null)
    const [motionDetector, setMotionDetector] = useState<MotionDetector | null>(null)
    const [recentMotion, setRecentMotion] = useState<boolean>(false)
    const [countdownStep, setCountdownStep] = useState<number>(initialCountdownSteps)
    const [executingRequest, setExecutingRequest] = useState<boolean>(false)
    const [videoHasStarted, setVideoHasStarted] = useState<boolean>(false)
    const [videoDimensions, setVideoDimensions] = useState<ViewportDimensions>(idealVideoDimensions)
    const [captureMode, setCaptureMode] = useState<CaptureMode>(CaptureMode.LOADING_VIDEO)
    const [matchStatus, setMatchStatus] = useState<MatchStatus>(MatchStatus.NOT_MATCHED_YET)
    const [shift, setShift] = useState<Shift | null>(null)
    const [worker, setWorker] = useState<Worker | null>(null)
    const [devDebugUserId, setDevDebugUserId] = useState<number | undefined>()
    const [decodedQrCode, setDecodedQrCode] = useState<string | undefined>()

    const history = useHistory()

    const isQrMode =
        operationalModeConfig &&
        [InteractionMode.QR, InteractionMode.TOUCH_QR].includes(operationalModeConfig.interactionMode)

    const videoOverlayDimensions: ViewportDimensions = isQrMode
        ? {
              width: 150,
              height: 150
          }
        : {
              width: 300,
              height: 380
          }

    const photoCanvasRef = useRef<HTMLCanvasElement>(null)
    const faceModelCanvasRef = useRef<HTMLCanvasElement>(null)
    const motionCanvasRef = useRef<HTMLCanvasElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const videoOverlayStyle = Object.assign(
        { ...videoOverlayDimensions },
        { top: (videoDimensions.height - videoOverlayDimensions.height) / 2 }
    )
    const isContactless: boolean = operationalModeConfig?.interactionMode !== InteractionMode.TOUCH
    const pictureCountdown = operationalModeConfig?.pictureCountdown || 3
    const idleRestartSeconds = operationalModeConfig?.idleRestartSeconds || 5

    const shouldAllowDevDebug = operationalModeConfig?.canUseDebugMode

    useEffect(() => {
        window.addEventListener("orientationchange", function () {
            setVideoDimensions(getUpdatedVideoDimensions())
        })
    }, [])

    useEffect(() => {
        if (videoHasStarted) {
            setVideoDimensions(getUpdatedVideoDimensions())
        }
        return () => {
            setVideoDimensions(idealVideoDimensions)
        }
    }, [videoHasStarted])

    useEffect(() => {
        if (!isContactless || isOffline) {
            return
        }
        loadLocalFaceModel(setLocalFaceModel)
        setMotionDetector(new SimpleMotionDetector())
    }, [isOffline])

    useEffect(() => {
        startVideo()
        return () => {
            stopVideo()
        }
    }, [])

    useInterval(
        () => {
            if (isOffline) {
                return
            }

            if (operationalModeConfig?.interactionMode === InteractionMode.CONTACTLESS) {
                const params = getRenderParamsIfValid(videoHasStarted, videoRef, faceModelCanvasRef)
                if (!params || !localFaceModel) {
                    return
                }

                drawVideoToCanvas(params)
                localFaceModel.updatePredictions(params.canvas, params.context, window.debugMode.drawAllCanvases)

                if (localFaceModel.hasValidFace()) {
                    setRecentMotion(true)
                } else {
                    setRecentMotion(false)
                    setCountdownStep(initialCountdownSteps)
                }
            } else if (photoCanvasRef.current && videoRef.current && areVideoDimensionsCorrect(videoRef.current)) {
                const context = photoCanvasRef.current.getContext("2d")

                if (!context) {
                    console.error(Message("missing2dContext"))
                    return
                }

                const params: DrawingSize = {
                    xOffset: 0,
                    yOffset: 0,
                    width: photoCanvasRef.current.width,
                    height: photoCanvasRef.current.height
                }

                context.drawImage(videoRef.current, params.xOffset, params.yOffset, params.width, params.height)
                const imageData = context.getImageData(0, 0, params.width, params.height)

                if (imageData) {
                    const code = jsQR(imageData.data, imageData.width, imageData.height, {
                        inversionAttempts: "dontInvert"
                    })

                    if (code && !!code.data) {
                        setDecodedQrCode(code.data)
                    }
                }
            }
        },
        isContactless && recentMotion ? 250 : null
    )

    useInterval(
        () => {
            if (!isContactless || isOffline) {
                return
            }

            const params = getRenderParamsIfValid(videoHasStarted, videoRef, motionCanvasRef)
            if (!params || !motionDetector) {
                return
            }

            drawVideoToCanvas(params)
            const motionDetectorParams: SimpleMotionParams = {
                context: params.context,
                drawMotionToCanvas: window.debugMode.drawAllCanvases,
                sampleSize: window.debugMode.motionPixelSampleSize,
                pixelDiffThreshold: window.debugMode.motionPixelDiffThreshold,
                motionFractionPercentageThreshold: window.debugMode.motionFractionPercentageThreshold
            }

            const motionResult: MotionResult = motionDetector.detectMotion(motionDetectorParams)
            motionResult.motionDetected ? setRecentMotion(true) : setRecentMotion(false)
        },
        isContactless && videoHasStarted && !recentMotion ? 250 : null
    )

    //  Countdown photo video
    useInterval(
        () => {
            if (isOffline) {
                return
            }

            if (captureMode !== CaptureMode.PHOTO_READY) {
                return
            }

            if (countdownStep === 0) {
                setCountdownStep(initialCountdownSteps)
                setRecentMotion(false)
                setVideoHasStarted(false)

                if (!window.debugMode.stopPhotoCapture) {
                    capturePhoto()
                }
            } else {
                setCountdownStep(countdownStep - 1)
            }
        },
        isContactless && localFaceModel && localFaceModel.hasValidFace() && recentMotion
            ? (pictureCountdown / 3) * 1000
            : null
    )

    useEffect(() => {
        if (isOffline) {
            return
        }

        if (decodedQrCode !== undefined && isQrMode) {
            setRecentMotion(false)
            setVideoHasStarted(false)
            capturePhoto()
        }
    }, [decodedQrCode, isQrMode, isOffline])

    const startVideo = async () => {
        if (!navigator.mediaDevices) {
            return
        }
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
            video: true
        })
        if (videoRef.current) {
            videoRef.current.srcObject = stream
            setCaptureMode(CaptureMode.PHOTO_READY)
        }
    }

    const stopVideo = () => {
        if (videoRef.current) {
            const mediaStream = videoRef.current.srcObject as MediaStream
            videoRef.current.srcObject = null
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => {
                    mediaStream.removeTrack(track)
                    track.stop()
                })
            }
        }
    }

    const restartVideo = () => {
        setCaptureMode(CaptureMode.LOADING_VIDEO)
        setMatchStatus(MatchStatus.NOT_MATCHED_YET)
        startVideo()
    }

    const executeSearchRequest = useCallback(
        async (face: any, workerId: number | undefined) => {
            if (!operationalModeConfig) {
                console.error(Message("noOperationalModeConfig"))
                return
            }

            if (isOffline) {
                return
            }

            try {
                const findFaceRequest: FindFaceRequest = {
                    includeEarlyFinish: await hasFeatureSwitch("ENABLE_EARLY_STOP_SHIFT_REASON"),
                    contextOrgId: operationalModeConfig.orgId,
                    interactionMode: operationalModeConfig.interactionMode,
                    siteIds: operationalModeConfig.sites.map(site => site.id)
                }

                if (workerId !== undefined) {
                    findFaceRequest.workerId = workerId
                } else if (!isQrMode) {
                    findFaceRequest.face = face
                } else {
                    findFaceRequest.qrCodeHash = decodedQrCode
                }

                const { success, shift, worker, contextForOrgId }: FindFaceResponse = await executeRequest({
                    endpoint: endpoints.worker.FIND_SHIFT,
                    withApiKey: true,
                    requestType: RequestType.POST,
                    params: findFaceRequest
                })

                if (success) {
                    setShift(shift)
                    setWorker(worker)
                    if (whiteLabelConfig.context !== contextForOrgId) {
                        const whiteLabelConfigToUse: WhiteLabelConfig = {
                            context: contextForOrgId,
                            lang: whiteLabelConfig.lang
                        }
                        onSetWhiteLabelConfig(whiteLabelConfigToUse)
                    }

                    // legacy - embedding "shift found" component in this component
                    setMatchStatus(MatchStatus.SHIFT_FOUND)

                    // new - redirecting to new path
                    if (operationalModeConfig.interactionMode === InteractionMode.TOUCH_QR) {
                        history.push(routes.SHIFT_FOUND, { shift, worker })
                    }
                } else {
                    throw new Error()
                }
            } catch (error: any) {
                if (error.message && error.message.indexOf("SHIFT_NOT_FOUND") >= 0) {
                    setMatchStatus(MatchStatus.SHIFT_NOT_FOUND)
                } else if (error.message && error.message.indexOf("INVALID_CREDENTIALS") >= 0) {
                    setMatchStatus(MatchStatus.INVALID_CREDENTIALS)
                } else {
                    Sentry.captureException(error, {
                        tags: {
                            customErrorMessage: "FindShift - FaceNotFound error"
                        }
                    })
                    setMatchStatus(MatchStatus.FACE_NOT_FOUND)
                }
            } finally {
                setCaptureMode(CaptureMode.PHOTO_TAKEN)
                setExecutingRequest(false)
            }
        },
        [
            Message,
            decodedQrCode,
            hasFeatureSwitch,
            isQrMode,
            onSetWhiteLabelConfig,
            operationalModeConfig,
            whiteLabelConfig,
            history,
            isOffline
        ]
    )

    const submitDevDebug = async (event: any) => {
        event.preventDefault()

        setExecutingRequest(true)
        executeSearchRequest(undefined, devDebugUserId)
    }

    const capturePhoto = useCallback(async () => {
        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        if (!photoCanvasRef.current || !videoRef.current) {
            console.error("couldn't find canvas ref")
            return
        }

        setExecutingRequest(true)

        const context = photoCanvasRef.current.getContext("2d")
        if (!context) {
            console.error(Message("missing2dContext"))
            return
        }

        const params: DrawingSize =
            localFaceModel && localFaceModel.hasValidFace()
                ? getFractionalDrawingSize(videoRef.current)
                : {
                      xOffset: 0,
                      yOffset: 0,
                      width: photoCanvasRef.current.width,
                      height: photoCanvasRef.current.height
                  }

        context.drawImage(
            videoRef.current,
            params.xOffset,
            params.yOffset,
            params.width,
            params.height,
            0,
            0,
            photoCanvasRef.current.width,
            photoCanvasRef.current.height
        )
        stopVideo()
        setCaptureMode(CaptureMode.PHOTO_TAKEN)

        if (!operationalModeConfig.sites || operationalModeConfig.sites.length === 0) {
            const { success, sites }: any = await executeRequest({
                endpoint: endpoints.agent.SET_SITES_OLD_TABLETS,
                withApiKey: true,
                requestType: RequestType.GET
            })

            if (success) {
                operationalModeConfig.sites = sites
                onSetOperationalModeConfig(operationalModeConfig)
            }
        }

        executeSearchRequest(context.canvas.toDataURL(imageParams.type, imageParams.quality), undefined)
    }, [Message, executeSearchRequest, localFaceModel, onSetOperationalModeConfig, operationalModeConfig])

    const visibilityStyle = (requiredCaptureMode: CaptureMode) => {
        return {
            display: captureMode === requiredCaptureMode ? "inline-block" : "none"
        }
    }

    const debugVisibilityStyle = () => {
        return {
            display: window.debugMode.drawAllCanvases ? "inline-block" : "none"
        }
    }

    const getUpdatedVideoDimensions = () => {
        if (!videoRef.current || !areVideoDimensionsCorrect(videoRef.current)) {
            return idealVideoDimensions
        }
        //  We want the displayed width to fill the whole ideal width...
        const scaleFactor = idealVideoDimensions.width / videoRef.current.videoWidth

        return {
            height: videoRef.current.videoHeight * scaleFactor,
            width: videoRef.current.videoWidth * scaleFactor
        }
    }

    const areVideoDimensionsCorrect = (videoElement: HTMLVideoElement): boolean => {
        return videoElement.videoWidth !== 0 && videoElement.videoHeight !== 0
    }

    if (matchStatus === MatchStatus.SHIFT_NOT_FOUND) {
        return (
            <FaceNotFound
                idleRestartSeconds={idleRestartSeconds}
                onRestart={restartVideo}
                interactionMode={operationalModeConfig?.interactionMode}
                title={Message("shiftNotFoundShort")}
                message={Message("shiftNotFound")}
            />
        )
    }

    if (matchStatus === MatchStatus.FACE_NOT_FOUND) {
        return (
            <FaceNotFound
                idleRestartSeconds={idleRestartSeconds}
                onRestart={restartVideo}
                interactionMode={operationalModeConfig?.interactionMode}
                title={Message("photoNotFoundShort")}
                message={Message("photoNotFound")}
            />
        )
    }

    if (matchStatus === MatchStatus.INVALID_CREDENTIALS) {
        return <InvalidCredentials />
    }

    if (matchStatus === MatchStatus.SHIFT_FOUND && shift && !shift.enableTimeAndAttendanceApp) {
        return (
            <NotEnabled
                idleRestartSeconds={idleRestartSeconds}
                interactionMode={operationalModeConfig?.interactionMode}
                onRestart={restartVideo}
            />
        )
    }

    if (matchStatus === MatchStatus.SHIFT_FOUND && shift && worker) {
        return (
            <ShiftFound
                idleRestartSeconds={idleRestartSeconds}
                shift={shift}
                worker={worker}
                onRestart={restartVideo}
                interactionMode={operationalModeConfig?.interactionMode}
            />
        )
    }

    let siteNames = ""
    if (operationalModeConfig?.sites && operationalModeConfig?.sites.length) {
        if (operationalModeConfig?.sites.length === 1) {
            siteNames = Message("welcomeToSite") + " " + operationalModeConfig?.sites[0].name
        } else {
            siteNames =
                Message("thisTabletIsConfiguredForSites") +
                " " +
                operationalModeConfig?.sites.map(site => site.name).join(", ")
        }
    }

    return (
        <div className="capturePhoto textAlignCenter">
            {captureMode === CaptureMode.LOADING_VIDEO && (
                <div className="loadingVideo">
                    <div className="loader"></div>
                    {Message("initialisingCamera")}
                </div>
            )}

            <div className="videoContainer" style={visibilityStyle(CaptureMode.PHOTO_READY)}>
                <div className={`videoOverlay ${isQrMode ? "videoOverlay--qr" : ""}`} style={videoOverlayStyle}></div>
                <video
                    style={videoDimensions}
                    ref={videoRef}
                    className="video"
                    playsInline
                    autoPlay
                    muted
                    onLoadedData={() => {
                        setVideoHasStarted(true)
                        setDecodedQrCode(undefined)
                    }}
                    onEnded={() => {
                        console.warn("Restarting video after ended!")
                        setVideoHasStarted(false)
                        restartVideo()
                    }}
                />
                {operationalModeConfig?.interactionMode === InteractionMode.CONTACTLESS && (
                    <CountdownLights countdownStep={countdownStep} />
                )}
            </div>
            <canvas
                ref={photoCanvasRef}
                className="video"
                width={videoDimensions.width}
                height={videoDimensions.height}
                style={visibilityStyle(CaptureMode.PHOTO_TAKEN)}
            />

            {window.debugMode.drawAllCanvases && (
                <div>
                    <div>{Message("motionDetected") + recentMotion}</div>
                    <div>{Message("countdownStep") + countdownStep}</div>
                </div>
            )}

            <canvas
                ref={faceModelCanvasRef}
                className="video"
                width={videoDimensions.width * viewportVideoFraction}
                height={videoDimensions.height * viewportVideoFraction}
                style={debugVisibilityStyle()}
            />
            <canvas
                ref={motionCanvasRef}
                className="video"
                width={videoDimensions.width * viewportVideoFraction}
                height={videoDimensions.height * viewportVideoFraction}
                style={debugVisibilityStyle()}
            />

            {(!isContactless || executingRequest) && (
                <div className="takePhoto">
                    <LoaderButton
                        onClick={capturePhoto}
                        disabled={captureMode === CaptureMode.LOADING_VIDEO}
                        loading={executingRequest}
                        className="button bigger"
                    >
                        {executingRequest ? Message("searchingShift") : Message("takePhoto")}
                    </LoaderButton>
                </div>
            )}

            {shouldAllowDevDebug && (
                <div>
                    <p className="marginBottom10">...or, enter a worker ID below:</p>

                    <form className="marginBottom20" onSubmit={submitDevDebug}>
                        <input
                            type="text"
                            onChange={event => {
                                setDevDebugUserId(parseInt(event.target.value))
                            }}
                        />

                        <button disabled={executingRequest} type="submit" className="devDebugBtn">
                            {executingRequest ? "Loading..." : "Go"}
                        </button>
                    </form>
                </div>
            )}

            {siteNames && <p className="bigger">{siteNames}</p>}
            {operationalModeConfig?.interactionMode === InteractionMode.CONTACTLESS && (
                <Fragment>
                    <p className="bigger">{Message("frontCamera")}</p>
                    <p className="bigger">{Message("takePhotoStill", [pictureCountdown + ""])}</p>
                </Fragment>
            )}

            {operationalModeConfig?.interactionMode === InteractionMode.TOUCH && (
                <p className="bigger">{Message("frontCameraButton")}</p>
            )}

            {operationalModeConfig?.interactionMode === InteractionMode.QR && (
                <p className="bigger">{Message("frontCameraQrMessage")}</p>
            )}
        </div>
    )
}

export default CapturePhoto
